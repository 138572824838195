<template>
  <div class="app-form">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">ID</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" :disabled="true" v-model="model.id">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Дата регистрации</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" :disabled="true" v-model="model.date">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Почта</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" :disabled="true" v-model="model.email">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Пароль</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="password1">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Повторите пароль</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="password2">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label">
        <!-- Left empty for spacing -->
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <button class="button is-primary" @click="doUpdate()">
            Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { getUser, changePasswordUser } from '@/api/user'
import { EventBus } from '@/utils'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: 'UserFormPasswd',
  components: {
    //
  },
  props: {
    //
  },
  data: function () {
    return {
      model: {
        id: null,
        date: null,
        mobile_phone: null,
        password: null
      },
      password1: null,
      password2: null
    }
  },
  created: function() {
    //
  },
  computed: {
    //
  },
  beforeMount: function(){
    //
  },
  mounted: function(){
    // let $this = this

    getUser(this.$route.params.id, this.$store.state.jwt_user)
      .then(response => {
        let data = response.data;
        
        let result = data.data
        result["date_expired"] = this.$moment(result["date_expired"], "YYYY-MM-DD hh:mm:ss")._d
        this.model = result

      })
      .catch(
        this.model = {}
      )

  },
  methods: {
    doUpdate () {
      let $this = this
      this.$buefy.dialog.confirm({
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {

          if (this.password1 == null || this.password2 == null || this.password1 == '' || this.password2 == '' || this.password1 != this.password2){

            Toast.open('Пароли не верны или пустые')
            return
          }

          this.model.password = this.password1
          
          changePasswordUser($this.model, this.$store.state.jwt_user).then(() =>
            {
              EventBus.$emit('successUpdateUser')
              this.$router.push({ name: 'a-users', params: {} })
            }
          )        

        }
      })
    },
  }
}
</script>

<style scoped>
  .app-form {
    text-align: left;
  }
</style>
<style>
</style>